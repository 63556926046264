<template>
    <Layout>
        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <h4 class="card-title">{{ $t('sms.send_sms') }}</h4>
                </div>
            </div>
            <div class="card-body">
                <component :is="toList ? 'list' : 'single'" @updateToList="updateToList"></component>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/table";
import Single from "./single";
import List from "./list";

export default {
    name: "send",

    components: {
        Layout,
        Single,
        List
    },

    data: function () {
        return {
            toList: false
        }
    },

    methods: {
        updateToList: function (boolean) {
            this.toList = boolean;
        },
    },

    created() {
        document.title = this.setTitle(this.$t('sms.send_sms'));
    }
}
</script>

<style scoped>

</style>
