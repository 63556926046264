export default {
    data: function () {
        return {
            link: {},
            showLinkModal: false,
            selected: null,
            selectionStart: null
        };
    },
    computed: {
        disabledLink: function () {
            return this.model?.message?.length > 0 && this.model?.message?.indexOf(String.fromCodePoint(128279)) !== -1;
        },
    },
    methods: {
        openShortLink: function () {
            this.selected = window.getSelection().toString();

            if (this.selected) {
                this.link.url = this.selected;
            }

            this.selectionStart = this.$refs.message.$el.selectionStart;
            this.showLinkModal = true;
        },

        addShortLink: function () {
            this.model.message = this.model.message || '';

            this.model.link = Object.assign({}, this.link);

            this.showLinkModal = false;
            let start = 0;
            let end = this.model.message?.length || 0;

            if (this.selected) {
                start = this.model.message.indexOf(this.selected);
                end = start + this.selected.length;
            } else if (this.selectionStart) {
                start = this.selectionStart;
                end = this.selectionStart;
            }

            this.model.message = this.model.message.substring(0, start) + String.fromCodePoint(128279) + this.model.message.substring(end);

            this.selected = null;
            this.selectionStart = null;
        },

        emptyLink: function () {
            this.link = {};
        }
    }
};
