<script>
export default {
    name: "short-link-form",

    props: {
        value: {},
    },

    data: function () {
        return {
            src: null,
            imageDimension: null,
            imageSize: null,
            imageSizeColor: null,
            imageDimensionColor: null
        };
    },

    computed: {
        model: {
            get: function () {
                return Object.assign(this.value, {follow: 'grouped'});
            },
            set: function (value) {
                this.$emit('input', value);
            },
        },
        options: function () {
            return [
                { text: this.$t('sms.link.grouped'), value: 'grouped' },
                { text: this.$t('sms.link.single'), value: 'single' }
            ];
        }
    },

    methods: {
        attachFile: function (event) {
            this.model.image = event?.target?.files?.[0];

            this.src = this.model.image ? URL.createObjectURL(this.model.image) : null;
        }
    },

    watch: {
        src: function (src) {
            if (!src) {
                this.imageDimension = null;
                this.imageSize = null;
                this.imageSizeColor = null;
                this.imageDimensionColor = null;
            } else {
                const size = this.model.image.size / 1000000;
                this.imageSize = size + ' MB';

                this.imageSizeColor = size < 8 ? 'text-success' : 'text-danger';

                const image = new Image();

                image.onload = () => {
                    this.imageDimension = image.width + 'x' + image.height;
                    this.imageDimensionColor = image.width < 1200 || image.height < 630 ? 'text-danger' : 'text-success';
                }

                image.src = this.src;
            }
        },
    }
}
</script>

<template>
    <b-form>
        <b-form-row>
            <b-col>
                <b-form-group
                    id="link"
                    :label="$t('sms.link.url')"
                    label-for="link-link-input"
                    label-cols="12"
                    content-cols="12"
                >
                    <b-form-input size="sm" id="link-link-input" required type="url" v-model="model.url" :placeholder="$t('sms.link.url')"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    id="link"
                    :label="$t('sms.link.title')"
                    label-for="link-title-input"
                    label-cols="12"
                    content-cols="12"
                >
                    <template #label>
                        <span class="me-2">{{ $t('sms.link.title') }}</span>
                        <small class="text-muted">{{ model.title?.length || 0 }} / 55</small>
                    </template>
                    <b-form-input size="sm" id="link-title-input" maxlength="55" required type="text" v-model="model.title" :placeholder="$t('sms.link.title')"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    id="link"
                    :label="$t('sms.link.description')"
                    label-for="link-description-input"
                    label-cols="12"
                    content-cols="12"
                >
                    <template #label>
                        <span class="me-2">{{ $t('sms.link.description') }}</span>
                        <small class="text-muted">{{ model.description?.length || 0 }} / 65</small>
                    </template>
                    <b-form-input size="sm" id="link-description-input" maxlength="65" required type="text" v-model="model.description" :placeholder="$t('sms.link.description')"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-3 mb-3">
                <b-form-group :label="$t('sms.link.follow_up')" label-class="font-weight-bold">
                    <b-form-radio-group
                        id="follow-up"
                        v-model="model.follow"
                        name="follow-up"
                    >
                        <b-tooltip target="follow-up-single">{{ $t('sms.link.single_description') }}</b-tooltip>
                        <b-tooltip target="follow-up-grouped">{{ $t('sms.link.grouped_description') }}</b-tooltip>
                        <span id="follow-up-grouped" class="me-4">
                            <b-form-radio name="follow-up" value="grouped">{{ $t('sms.link.grouped') }}</b-form-radio>
                        </span>
                        <span id="follow-up-single">
                            <b-form-radio name="follow-up" value="single">{{ $t('sms.link.single') }}</b-form-radio>
                        </span>
                    </b-form-radio-group>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    id="link"
                    :label="$t('sms.link.image')"
                    label-for="link-image-input"
                    label-cols="12"
                    content-cols="12"
                >
                    <template #label>
                        <span class="me-2">{{ $t('sms.link.image') }}</span>
                        <small class="text-muted me-2">1200x630 | 8 MB</small>
                    </template>
                    <input id="link-image-input" ref="file" type="file" @change="attachFile" class="d-none" />
                    <div class="w-100 d-flex flex-column align-items-center justify-content-center">
                        <label for="link-image-input" class="cursor-pointer font-size-24 upload-avatar" >
                            <i v-if="!src" class="fas fa-file-image"></i>
                            <img ref="image" v-if="src" :src="src" :alt="$t('sms.link.image')" />
                        </label>
                        <small class="text-muted" :key="imageSize + '_' + imageDimension">
                            <span :class="imageDimensionColor">
                                <i v-if="imageDimension" class="fas me-1" :class="imageDimensionColor === 'text-danger' ? 'fa-exclamation-triangle' : 'fa-check'"></i>
                                {{ imageDimension }}
                            </span>
                            <span class="mx-2" v-if="imageDimension && imageSize">|</span>
                            <span :class="imageSizeColor">
                                <i v-if="imageSize" class="fas me-1" :class="imageSizeColor === 'text-danger' ? 'fa-exclamation-triangle' : 'fa-check'"></i>
                                {{ imageSize }}
                            </span>
                        </small>
                    </div>
                </b-form-group>
            </b-col>
        </b-form-row>
    </b-form>
</template>

<style lang="scss" scoped>
.upload-avatar {
    border: 3px solid gray;
    border-radius: 10px;
    width: 300px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}
</style>
